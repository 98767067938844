import GlobalLayout from '@/components/GlobalLayout/GlobalLayout'
import { NewUserDiscount } from '@/components/NewUserDiscount/NewUserDiscount'
import { PayQrDialog } from '@/components/PayQrDialog'
import { toastController } from '@/controllers/toastController'
import { uiController } from '@/controllers/uiController'
import { api } from '@/helpers/api'
import { commonUtils } from '@/helpers/commonUtils'
import { storageHelper } from '@/helpers/storageHelper'
import { routerService } from '@/services/routerService'
import { RouterServiceTypes } from '@/services/routerService/serviceTypes'
import { userService } from '@/services/userService'
import cls from 'classnames'
import { observer } from 'mobx-react'
import { useRef, useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { useMount, useUnmount } from 'react-use'
import style from './LoginPage.module.less'
import { RegisterCheckPhoneNumber } from '@/components/RegisterCheckPhoneNumber/RegisterCheckPhoneNumber'
import classNames from 'classnames'
import { buyVipService } from '../../services/buyVipService/BuyVipService'

export const MAX_ERROR_COUNT = 3

interface Plan extends ServerDataTypes.VipPlan {
  priceStr: string
  originalPrice: string
  perPrice: string
  id: string
  offCount: number
}

export const getLoginParams = () => {
  try {
    const utm = storageHelper.get(['utm']).utm || ''
    const referer = storageHelper.get(['referer']).referer || ''
    const kword = storageHelper.get(['kword']).kword || ''
    const inviteCode = storageHelper.get(['inviteCode']).inviteCode
    const bdVid = storageHelper.get(['bdVid']).bdVid || ''
    const bdUrl = storageHelper.get(['bdUrl']).bdUrl || ''
    const qhclickid = storageHelper.get(['qhclickid']).qhclickid || ''
    const installSource = storageHelper.get(['installSource']).installSource || ''
    let refererChannel = ''
    if (referer) {
      refererChannel = encodeURIComponent(new URL(referer).hostname)
    }
    return {
      utm,
      kword,
      refererChannel,
      refererChannelDetail: encodeURIComponent(referer),
      /** 增加浏览器类型 */
      browserType: commonUtils.getBrowserInfo().browser,
      /** 增加百度ocpc投放参数 */
      bdVid,
      bdUrl: encodeURIComponent(bdUrl),
      /** 增加 360 ocpc 投放参数 */
      qhclickid,
      registerChannel: installSource,
      ...(inviteCode ? { inviteCode } : {}),
    }
  } catch (error) {
    return {
      utm: '',
      kword: '',
      refererChannel: '',
      refererChannelDetail: '',
    }
  }
}

const LoginPage = observer(() => {
  const [params] = useSearchParams()
  const queryParam = Array.from(params.entries()).reduce((prev, cur) => {
    const key = cur[0]
    const value = cur[1]
    return { ...prev, ...{ [key]: value } }
  }, {} as RouterServiceTypes.PageParams['login'])
  const [type] = useState<'login' | 'register'>(queryParam?.type || 'login')
  const [from] = useState<string>(queryParam?.from || '')
  const [redirectUrl] = useState<string>(queryParam?.redirectUrl || '')

  const [currentStep, setCurrentStep] = useState<'login' | 'discount' | 'phone'>('login')
  const [qrCode, setQrCode] = useState('')
  const [discount, setDiscount] = useState<null | Plan>(null)
  /** TODO: 待晓娟处理 支付弹窗相关变量 */
  const [showPayQr, setShowPayQr] = useState<boolean>(false)
  const [discountEndTime, setDiscountEndTime] = useState<number | undefined>()

  const pageUnload = useRef<boolean>(false)
  const checkErrorCount = useRef<number>(0)

  useMount(async () => {
    await userService.updateUserInfo()
    const userInfo = userService.userData
    if (userInfo && userInfo.newUserDiscount) {
      const discountEndTime = userInfo.createdAt * 1000 + 48 * 60 * 60 * 1000
      setDiscountEndTime(discountEndTime)
      if (discountEndTime > Date.now()) {
        setCurrentStep('discount')
        renderDiscountPanel()
        return
      }
    }
    initLoginQrCode()
    if (from === 'extension_install') {
      userService.trackUserRecord('installed')
    }
  })

  useUnmount(() => {
    pageUnload.current = true
  })

  const initLoginQrCode = async () => {
    try {
      const params = getLoginParams()
      console.log('params', params)
      const { sceneId, url } = await api.login.getLoginQrCode(params)
      setQrCode(url)
      checkLoginStatus(sceneId)
    } catch (error) {
      toastController.error('登录二维码获取失败，请稍后重试或联系客服')
    }
  }

  const checkUserStatus = async () => {
    await userService.updateUserInfo()
    const userInfo = userService.userData

    if (!userInfo) {
      console.warn('userInfo:', userInfo)
      return
    }

    const discountEndTime = userInfo.createdAt * 1000 + 48 * 60 * 60 * 1000
    const currentTime = Date.now()

    if (userInfo.newUserDiscount && discountEndTime > currentTime) {
      renderDiscountPanel()
    } else {
      userService.trackUserRecord('login_success')
      await commonUtils.asyncDelay(1.5 * 1000)
      doJump()
    }
  }

  const checkLoginStatus = async (sceneId: string) => {
    if (pageUnload.current) {
      return
    }
    try {
      const { status } = await api.login.checkLoginStatus(sceneId)
      if (status === 'no_scan') {
        await commonUtils.asyncDelay(1 * 1000)
        checkLoginStatus(sceneId)
      } else {
        /** 如果登录用过 inviteCode,则在登录成功后，清除本地的 inviteCode */
        if (storageHelper.get(['inviteCode']).inviteCode) {
          storageHelper.set({ inviteCode: '' })
        }
        // 登录成功 清理 bdUrl/bdVid
        storageHelper.set({ bdUrl: undefined })
        storageHelper.set({ bdVid: undefined })
        // 登录成功 清理 qhclickid
        storageHelper.set({ qhclickid: undefined })
        toastController.success('登录成功')
        await userService.updateUserInfo()
        const userInfo = userService.userData
        try {
          commonUtils.sendExtensionMessage('markAuth', {
            isRegister: type === 'register',
          })
        } catch (error) {
          //
        }
        //  登录成功要判断一下能不能享受新人福利
        const discountEndTime = userInfo!.createdAt * 1000 + 48 * 60 * 60 * 1000
        setDiscountEndTime(discountEndTime)

        // 注册时间
        const registerTime = userInfo!.createdAt * 1000
        // 当前时间
        const currentTime = Date.now()
        // 当前时间减去注册时间，小于1分钟。 并且没有手机号。就是新用户
        if (currentTime - registerTime < 60 * 1000 && !userInfo?.phoneNumber) {
          setCurrentStep('phone')
        } else {
          checkUserStatus()
        }
      }
    } catch (error) {
      console.log('error', error)
      checkErrorCount.current += 1
      if (checkErrorCount.current < MAX_ERROR_COUNT) {
        await commonUtils.asyncDelay(1 * 1000)
        checkLoginStatus(sceneId)
      } else {
        toastController.error('登录状态检测失败，请刷新页面重试或联系客服')
      }
    }
  }

  const renderDiscountPanel = async () => {
    const { dataList } = await buyVipService.getUserWelfare()

    const discountPlan = dataList[0]
    if (!discountPlan) {
      doJump()
      return
    }

    userService.trackUserRecord(getUserRecordStr('show_new_user_discount_w'))
    const { price, count, timeType, originPrice } = discountPlan
    setDiscount({
      ...discountPlan,
      priceStr: Math.floor(price / 100).toString(),
      perPrice:
        timeType === 'year'
          ? Math.floor(price / (12 * count * 100)).toString()
          : Math.floor(price / (count * 100)).toString(),
      originalPrice: Math.floor(originPrice / 100).toString(),
      offCount: Math.floor((price / originPrice) * 10),
    })
    setCurrentStep('discount')
  }

  const getUserRecordStr = (str: string) => {
    return `${str}_B`
  }

  const doJump = () => {
    if (from === 'pricing') {
      routerService.redirectTo('pricing', { from: 'login' })
      return
    }
    if (redirectUrl) {
      window.location.href = decodeURIComponent(redirectUrl)
      return
    }
    if (commonUtils.isInstalledExtension()) {
      routerService.redirectTo('welcome', { from: type })
      return
    }
    routerService.redirectTo('install', { from: 'login' })
  }

  const phoneCheckSucceed = async (number: string) => {
    checkUserStatus()
  }

  return (
    <GlobalLayout pageHeaderVisible rightBoxVisible={false} hideFooter>
      <section className={cls(style.loginPage, 'flex-col')}>
        <section className={style.bg}></section>
        <img
          className={style.bgImg}
          src='https://assets.weibanzhushou.com/web/pt-web/login-page_banner__bg.e9a4d7829977.png'
        />
        {currentStep === 'login' && (
          <div className={'col-1'}>
            <section className={style.loginBox}>
              <p className={style.title}>{type === 'login' ? '登录' : '注册'}「会译」</p>
              <p className={style.subTitle}>
                <img
                  className={style.icon}
                  src='https://assets.weibanzhushou.com/web/pt-web/icon__weixin.3f019d52c4d6.png'
                />
                <span className={style.text}>请使用微信扫码</span>
              </p>
              <div className={style.qrCodeBox}>
                {qrCode ? (
                  <img className={style.qrCode} src={qrCode} />
                ) : (
                  <img
                    className={style.loadingIcon}
                    src='https://assets.weibanzhushou.com/web/pt-web/loading.0e6e0f46d750.gif'
                  />
                )}
              </div>
              <div className={style.tipBox}>
                <p className={style.tipText}>微信扫码关注「会译」公众号，即可登录，无需注册</p>
                <p className={style.tipText}>
                  扫码即同意{' '}
                  <Link
                    target='_blank'
                    to={
                      'https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%8F%8A%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.2e0d8664cd55.pdf'
                    }
                  >
                    《用户协议》
                  </Link>
                </p>
              </div>
            </section>
          </div>
        )}

        {currentStep === 'phone' && (
          <div className={'col-1'}>
            <div className={classNames(style.phoneWrap)}>
              <div className={classNames(style.paddingWrap)}>
                <RegisterCheckPhoneNumber
                  onSuccess={(number) => phoneCheckSucceed(number)}
                ></RegisterCheckPhoneNumber>
              </div>
            </div>
          </div>
        )}

        {currentStep === 'discount' && discountEndTime && discount && (
          <NewUserDiscount
            discountEndTime={discountEndTime}
            discount={discount}
            onSkipClick={() => {
              doJump()
            }}
            onJoinClick={() => {
              setShowPayQr(true)
            }}
          />
        )}
        <section className={style.footerBox}>
          <Link
            target='_blank'
            to='https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%E5%8F%8A%E6%9C%8D%E5%8A%A1%E5%8D%8F%E8%AE%AE.2e0d8664cd55.pdf'
          >
            用户协议
          </Link>
          <Link
            target='_blank'
            to='https://assets.weibanzhushou.com/web/pt-web/%E4%BC%9A%E8%AF%91-%E9%9A%90%E7%A7%81%E4%BF%9D%E6%8A%A4%E6%94%BF%E7%AD%9620240930.pdf'
          >
            隐私政策
          </Link>
        </section>
        {/* 支付二维码弹窗 */}
        {showPayQr && (
          <PayQrDialog
            open={showPayQr}
            plan={discount!}
            source={`new_user_discount_buy`}
            paySuccess={() => {
              // 支付成功 关闭弹窗
              uiController.paySuccessDialogProps = { open: true, enableGiftReceiveBtn: true }
              setShowPayQr(false)
              doJump()
            }}
            onClose={() => {
              setShowPayQr(false)
              doJump()
            }}
          ></PayQrDialog>
        )}
      </section>
    </GlobalLayout>
  )
})

export default LoginPage
