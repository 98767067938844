import { BuyTokenPackageDialogProps } from '@/components/BuyTokenPackageDialog'
import { BuyVipDialogV1Props } from '@/components/BuyVipDialogV1'
import { ExchangeCodeDialogProps } from '@/components/ExchangeCodeDialog'
import { PaySuccessEducationDiaLogProps } from '@/components/PaySuccessEducationDiaLog'
import { PaySuccessDialogProps } from '@/components/PaySuccessDialog'
import { PdfUpgradeModalProps } from '@/components/PdfUpgradeModal'
import { ModalProps } from 'antd'
import { MessageInstance } from 'antd/es/message/interface'
import { makeObservable, observable } from 'mobx'
import { api } from '@/helpers/api'
import { userService } from '@/services/userService'
import { Plan } from '@/components/NewUserDiscount/NewUserDiscount'
import { buyVipService } from '@/services/buyVipService'

interface CommonModalProps extends ModalProps {
  content?: string
}
class UiController {
  @observable message!: MessageInstance
  @observable commonModalProps: CommonModalProps = { open: false }
  @observable paySuccessDialogProps: PaySuccessDialogProps = {
    open: false,
  }

  @observable paySuccessEducationDialogProps: PaySuccessEducationDiaLogProps = {
    open: false,
  }

  @observable buyTokenPackageDialogProps: BuyTokenPackageDialogProps = {
    open: false,
  }

  @observable buyVipDialogV1Props: BuyVipDialogV1Props = {
    open: false,
  }

  @observable exchangeDialogProps: ExchangeCodeDialogProps = {
    open: false,
  }
  @observable pdfUploadTimeLimitedProps: PdfUpgradeModalProps = {
    open: false,
  }
  @observable pdfChatTimeLimited = false
  @observable pdfUploadPageLimitedProps: PdfUpgradeModalProps = {
    open: false,
  }

  @observable loginModalProps: {
    open: boolean
    handleLoginSuccess?: () => void
  } = {
    open: false,
  }

  @observable operationRecordModalVisible = false

  @observable showDiscountModal = false
  @observable showPayQr = false
  @observable discount: Plan | undefined = undefined
  @observable discountEndTime: number | undefined = undefined
  @observable showPhoneNumberModal = false

  public init() {
    makeObservable(this)
  }

  public async checkNewUserDiscount() {
    const { dataList } = await buyVipService.getUserWelfare()
    const discountPlan = dataList[0]
    if (!discountPlan) {
      this.discount = undefined
      return false
    }

    if (userService.userData && userService.userData.newUserDiscount) {
      const discountEndTime = userService.userData.createdAt * 1000 + 48 * 60 * 60 * 1000

      if (discountEndTime > Date.now()) {
        this.discountEndTime = discountEndTime

        const getUserRecordStr = (str: string) => {
          return `${str}_B`
        }

        userService.trackUserRecord(getUserRecordStr('show_new_user_discount_w'))
        const { price, count, timeType, originPrice } = discountPlan
        this.discount = {
          ...discountPlan,
          priceStr: Math.floor(price / 100).toString(),
          perPrice:
            timeType === 'year'
              ? Math.floor(price / (12 * count * 100)).toString()
              : Math.floor(price / (count * 100)).toString(),
          originalPrice: Math.floor(originPrice / 100).toString(),
          offCount: Math.floor((price / originPrice) * 10),
        }

        return true
      }
    }

    return false
  }

  public async openDiscount() {
    const showDiscount = await this.checkNewUserDiscount()
    if (showDiscount) {
      this.showPayQr = true
    }
  }

  public async openDiscountOrClose() {
    const showDiscount = await this.checkNewUserDiscount()
    if (showDiscount) {
      this.showDiscountModal = true
    } else {
      this.closeLoginModal()
    }
  }

  public async loginSuccess() {
    const userInfo = userService.userData
    await userService.updateUserInfo()

    // 注册时间
    const registerTime = userInfo!.createdAt * 1000
    // 当前时间
    const currentTime = Date.now()
    if (currentTime - registerTime < 60 * 1000 && !userInfo?.phoneNumber) {
      // 刚注册的用户，填写手机号
      this.showPhoneNumberModal = true
    } else {
      this.openDiscountOrClose()
    }
  }

  public closeLoginModal() {
    uiController.loginModalProps.handleLoginSuccess?.()
    uiController.loginModalProps = {
      open: false,
    }
  }
}

export const uiController = new UiController()
