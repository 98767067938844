import { ConfigProvider, message } from 'antd'
import { useEffect, useLayoutEffect } from 'react'
import {
  BrowserRouter,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import './App.less'
import { Router } from './Router'
import CommonDialogLayout from './components/CommonDialogLayout/CommonDialogLayout'
import { uiController } from './controllers/uiController'
import { PAGE_LINKS } from './helpers/commonConsts'
import { commonUtils } from './helpers/commonUtils'
import { envHelper } from './helpers/envHelper'
import './helpers/flexible'
import { pdfTranslateHelper } from './helpers/pdfTranslateHelper/pdfTranslateHelper'
import { pdfTranslateHelper as pdfTranslateHelperV1 } from './helpers/pdfTranslateHelperV1/pdfTranslateHelper'
import { storageHelper } from './helpers/storageHelper'
import { themeHelper } from './helpers/themeHelper'
import { routerService } from './services/routerService/routerService'
import { translateService } from './services/translateService'
import { userService } from './services/userService'
import { ptProxyHelper } from './helpers/ptProxyHelper'
envHelper.init()
themeHelper.init()

const RouterLayout = () => {
  // 只要使用了 useNavigate ，url 变化都会导致此组件重新渲染
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = useParams()
  routerService.navigate = useNavigate()
  const location = useLocation()
  routerService.location = location
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setSearchParams] = useSearchParams()
  routerService.setURLSearchParams = setSearchParams
  useLayoutEffect(() => {
    routerService.params = {
      ...params,
      ...commonUtils.getUrlParams(),
    }
  })
  // 记录插件的安装路径
  useEffect(() => {
    const { isVisitedPage, installSource } = storageHelper.get(['isVisitedPage', 'installSource'])
    if (isVisitedPage || installSource) {
      return
    }
    storageHelper.set({ isVisitedPage: true })
    const { pathname } = location
    const { from } = routerService.params
    // 用户如果未访问过其他页面（例如官网/下载页），直接访问登录页，则认为是从谷歌商店安装
    if (pathname.startsWith(PAGE_LINKS.login) && from === 'extension_install') {
      const browserType = commonUtils.getBrowserInfo().browser.toLowerCase()
      storageHelper.set({ installSource: `${browserType}_store` })
    }
  })
  return null
}

export default function App() {
  const [messageApi, contextHolder] = message.useMessage()
  uiController.message = messageApi
  return (
    <ConfigProvider button={{ autoInsertSpace: false }} theme={themeHelper.theme}>
      <BrowserRouter>
        <CommonDialogLayout />
        {contextHolder}
        <RouterLayout />
        <Router />
      </BrowserRouter>
    </ConfigProvider>
  )
}

// init controllers
uiController.init()
pdfTranslateHelperV1.init()
pdfTranslateHelper.init()
ptProxyHelper.init()

// init services
userService.init()
translateService.init()
